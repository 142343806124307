.containerResult{
  padding-top: 10px;
  padding: 7%;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.resultTitle{
  color: #474747;
  font-size: 30px;
  line-height: 1.1;
  font-weight: 600;
}

.resultDescription{
  color: #000000;
  font-size: 20px;
  line-height: 1.1;
  text-align: center;
  padding-bottom: 20px;
}

.containerResultTesting{
  color: #fff;
  background-color: #28416B;
  border-radius: 30px;
  font-size: 30px;
  width: 100%;
  padding-bottom: 70px;
}

.resultTitleTesting{
  font-size: 30px;
  line-height: 1.1;
  font-weight: 600;
}

.resultDescriptionTesting{
  font-size: 30px;
  line-height: 1;
}

.resultDescriptionTestingTotal{
  font-size: 35px;
  line-height: 1;
}

.containerResultInformation{
  color: #000000;
  background-color: #ffc934;
  border-radius: 30px;
  font-size: 30px;
  padding: 10px;
  margin-top: -70px !important;
  padding-bottom: 20px;
}
