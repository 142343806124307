.containerDiagnostic{
  padding-top: 10px;
  padding: 7%;
}

.diagnosticTitle{
  color: #28416B;
  font-size: 30px;
  line-height: 1.1;
  font-weight: 600;
}

.diagnosticSubtitle{
  color: #000000;
  font-size: 25px;
  line-height: 1.1;
  font-weight: 600;
}

.diagnosticDescription{
  color: #474747;
  font-size: 20px;
  line-height: 1.1;
  text-align: center;
  padding-bottom: 20px;
}

.diagnosticChronometerContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.diagnosticButton{
  border-radius: 30px;
}

.diagnosticChronometer{
  border: 2px solid #28416B;
  border-radius: 30px;
  padding: 5px 12px;
}

.diagnosticTemplateOneContainer{
  border: 2px solid #28416B;
  border-radius: 30px;
  padding: 30px;
  text-align: left;
  white-space: break-spaces;
}

.diagnosticTemplateOneImageOne{
  width: 400px;
  padding: 15px;
  border-radius: 30px;
}

.diagnosticTemplateOneTitle{
  color: #28416B;
  font-size: 35px;
  line-height: 1.1;
  font-weight: 600;
}

.diagnosticTemplateOneDescription{
  color: #474747;
  font-size: 20px;
  line-height: 1.1;
  text-align: left;
}

.diagnosticTemplateTwoContainer{
  border: 2px solid #28416B;
  border-radius: 30px;
  padding: 30px;
  text-align: left;
  white-space: break-spaces;
}

.diagnosticTemplateTwoImageOne{
  width: 400px;
  padding: 15px;
  border-radius: 30px;
}

.diagnosticTemplateTwoTitle{
  color: #28416B;
  font-size: 35px;
  line-height: 1.1;
  font-weight: 600;
}

.diagnosticTemplateTwoDescription{
  color: #474747;
  font-size: 20px;
  line-height: 1.1;
  text-align: left;
}
