.carruselContainer{
  flex: 1;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.carruselParagraphTitle{
  font-size: 30px;
  text-align: left;
  line-height: 1.1;
  font-weight: 600;
  color: #474747;
}

.carruselParagraphSubtitle{
  font-size: 20px;
  text-align: left;
  line-height: 1.1;
  font-weight: 600;
  color: #474747;
}

.carruselStrongBlue{
  color: #28416B;
}

.carruselStrongYellow{
  color: #ffc934;
}

.carruselImageOptionsWoman{
  width: 380px;
}

.carruselImageOptionsMen{
  width: 450px;
}

.containerRegister{
  padding: 10% 10px !important;
  flex: 1;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.containerRegisterForm {
  background-color: #28416B;
  border-radius: 22px;
  color: #fff;
}

.containerRegisterFormTitle{
  padding: 15px;
}

.registerFormTitle{
  font-size: 18px;
  text-align: center;
  line-height: 1.1;
  font-weight: 600;
}

.registerFormSubtitle{
  font-size: 12px;
  text-align: center;
  line-height: 1.1;
  margin-bottom: -5px;
}

.containerFormFields {
  background-color: #fff;
  color: #474747;
  border-radius: 20px;
  border: 2px solid #28416B;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 20px;
}

.containerFormButton{
  border-radius: 30px;
  color: #474747;
}

/* .formRegisterFields{
  ENTRARON EN CONFLICTO CON LA LIBRERIA MATERIAL UI, SE CREARON LOS ESTILOS EN StylesMaterialUI.JS
} */

.formRegisterConditions{
  width: 100%;
}

.registerFormImagesFlags{
  width: 25px;
  height: 25px;
}
