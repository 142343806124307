.testingContainer{
    padding-top: 10px;
    padding: 7%;
}

.testingTitle{
    color: #000000;
    font-size: 25px;
    line-height: 1.1;
    font-weight: 600;
}

.testingDescription{
    color: #474747;
    font-size: 20px;
    line-height: 1.1;
    text-align: center;
    padding-bottom: 20px;
}

.testingQuestionsContainer{
    padding: 10px;
    display: flex;
    flex-direction: column !important;
    align-items: flex-start;
}

.testingQuestionLabel{
    text-align: left;
    color: #28416B !important;
    font-size: 20px !important;
    line-height: 1.1 !important;
    font-weight: 600 !important;
}

.testingQuestionRadioButtonContainer{
    padding: 9px !important;
}

.testingQuestionLabelRadioButton{
    color: #474747;
    font-size: 20px;
    line-height: 1.1;
    padding: 3px 8px 20px !important;
}

.testingButtonContainer{
    align-items: center;
}

.testingButton{
    border-radius: 30px;
  }
  
